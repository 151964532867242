import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Box,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/PersonRemove";
import InviteIcon from "@mui/icons-material/HowToReg";
import { useNavigate } from "react-router-dom";
import SimpleBackDrop from "./SimpleBackDrop";
import { supabase } from "../supabase";
import PropTypes from "prop-types";

const steps = ["Introduction", "Survey", "Network Scan", "Results", "Next Steps"];

export function Assessment() {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    // make sure steps complete, save stuff
    if (activeStep >= 3) {
      setOpen(false);
      window.location.reload(false);
    }
  };

  function getStepContent(step) {
    let result = "";
    switch (step) {
      case 0:
        return (
          <Box sx={{ "& > :not(style)": { m: 2 } }}>
            <Typography>Welcome to the KnowBreach Solutions "NO BS Assessment"!</Typography>
            <Typography>
              This isn't your father's CyberSecurity assessment! We strive to
              make this process easy to understand, and work to get you results in as
              few steps as possible.
            </Typography>
            <Typography>
              The first part of the assessment is a survey. This portion of the
              assessment is adaptive based on your answers to each question. In
              other words if your answer makes the next question irrelevant or
              tells us what we need to know without further information, we
              won't waste your time asking you more on that topic. We have tried
              to make each question as straight forward as possible, but if you
              are ever lost, each question has a "Why are you asking this?" link
              that will explain why we ask the question and what we are looking
              for. Typically people can get through this in as few as 10 questions, 
              but never more than 25.
            </Typography>
            <Typography>
              After we ask our questions, we will ask your permission to run an
              automated network and dark web scan. This scan will look for any
              equipment or applications that are exposed to the internet as well
              as any data that may have been leaked to the dark web. We will
              then check those exposures for weaknesses and vulnerabilities
              hackers can use to breach your environment.
            </Typography>
            <Typography>
              After the scan is complete, we will provide you with a report that
              shows the results of the survey and the scan and where you stack
              up amongst your peers. We will also highlight your risks and give
              suggestions for next steps you can take to improve your situation.
            </Typography>
            <Typography>
              Sound good? Of course it does! We are awesome! Lets get started!
              Please enter your email below so we can begin. We will use this to
              lookup your company and save your progress. We don't sell your
              info, we promise and we will even show you where others have
              already done so!
            </Typography>
            <TextField label="Email" fullWidth margin="normal" />
          </Box>
        );

      case 1:
        return (
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Typography>Survey</Typography>
          </Box>
        );

      case 2:
        return (
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Typography>Scan</Typography>
          </Box>
        );

      case 3:
        return (
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Typography>Results</Typography>
          </Box>
        );

      case 4:
        return (
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Typography>Next Steps</Typography>
          </Box>
        );
      default:
        setOpen(false);
        navigate("/");
    }
  }

  return (
    <Dialog maxWidth="lg" fullWidth onClose={handleClose} open={open}>
      <DialogTitle>NO BS Assessment Wizard</DialogTitle>

      <Box sx={{ m: 4, height: "60vh" }}>
        <Stepper sx={{ m: 4 }} variant="outlined" activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          <Box sx={{ m: 4 }}>{getStepContent(activeStep)}</Box>
          <Box
            sx={{ display: "flex", position: "absolute", bottom: 10, pt: 2 }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{ mr: 1 }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      </Box>
    </Dialog>
  );
}

export default Assessment;
