import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import AuthUI from "./components/AuthUI";
import SimpleBackDrop from "./components/SimpleBackDrop";
import React, { useState, useEffect } from "react";
import { Assessment } from "./components/Assessment";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [domainInfo, setDomainInfo] = useState(null);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const user = useUser();
  const supabase = useSupabaseClient();

  /* useEffect(() => {
    //const domain_name = user?.email.split("@")[1];
    const domain_name = 'wardlawsc.com';

    async function fetchData() {
      setIsLoading(true);
      const url = "https://api.knowbyte.app/api/getDomainInfo/" + domain_name;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.emails) {
            setEmailAddresses(
              data.emails.map((email) => ({
                customer_id: user?.id,
                email: email.email || "",
                full_name: email.full_name || "",
                department: email.department || "",
                title: email.title || "",
                breached: email.breach,
                exposed: email.exposed,
                valid: false,
              }))
            );
  
            setCompanyInfo({
              id: user?.id,
              twitter: data.socialProfiles[0].twitter,
              facebook: data.socialProfiles[0].facebook,
              linkedin: data.socialProfiles[0].linkedin,
              instagram: data.socialProfiles[0].instagram,
              youtube: data.socialProfiles[0].youtube,
              address: data.address,
              industry: data.businessType,
              name: data.companyName,
              phone: data.phone,
              website: "https://" + domain_name,
            });
  
            setDomainInfo({
              customer_id: user?.id,
              domain: domain_name,
              mx: data.mxRecord,
              ns: data.nsRecord,
              hosting: data.hosting,
              webserver: data.webserver,
              tech: data.tech,
              mailfilter: data.mailfilter,
              spfrecord: data.spfrecord,
              webgateway: data.webgateway,
              services: data.services,
              subdomains: data.subdomains,
      
            });
            const foundUser = emailAddresses.find(
              (email) => email.email === user?.email
            );
            let formInfo = {};
            if (foundUser) {
              formInfo = foundUser;
            } else {
              formInfo = {
                email: user?.email,
                full_name: "",
                title: "",
                department: "",
              };
            }
            setUserInfo(formInfo);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          setIsLoading(false);
        });
    }
    if (user?.email) {fetchData();}
  }, [user]); */
  
/*   if (isLoading) {
    return <SimpleBackDrop/>;
  } */

/*   //loop through email addresses and calculate number with breached set to true
  let totalBreaches = 0;
  emailAddresses.forEach((email) => {
    if (email.breached === true
      ) {
      totalBreaches++;
    };
  });
 */

  return (
    <>
    <Assessment/>
    </>
  );

}

export default App;
